<template>
    <div class="taskSearch">
        <van-search v-model="value" show-action placeholder="输入达人昵称/抖音ID/运营人员" clearable maxlength="20">
            <template #action>
                <div @click="onSearch">搜索</div>
            </template>
        </van-search>
        <div class="seach-history">
            <div class="title flex">
                <div>搜索历史</div>
                <img :src="deleteImg" class="delete-img" @click="deleteClick">
            </div>
            <div class="search-wrap flex">
                <div class="search-item" v-for="(item, index) in searchArr" :key="index" @click="searchitemClick(item)">{{item}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import common from '@/utils/common'
    export default {
        data() {
            return{
                value: null,
                deleteImg: require('@/assets/img/site/delete.png'),
                pageIndex: 1,
                pageSize: 10,
                isLoading: false,
                loading: false,
                searchArr: []
            }
        },
        mounted() {
            this.searchArr = JSON.parse(common.getItem('searchArr'))
        },
        methods:{
            searchitemClick(item){
                this.value = item
                this.onSearch()
            },
            searchFilter() {
                if(!this.value) return
                let flag = false
                if(this.searchArr.length != 0){
                    for(let [index,obj] of this.searchArr.entries()){
                        if(this.value == obj){
                            this.searchArr.splice(index, 1)
                            this.searchArr.unshift(this.value)
                            flag = true
                        }
                    }
                }
                if(!flag){
                    this.searchArr.unshift(this.value)
                }
                if(this.searchArr.length > 20){
                    this.searchArr.pop()
                }
                common.setItem('searchArr', JSON.stringify(this.searchArr) )
            },
            onSearch(){
                this.searchFilter()
                this.$router.push({name: 'myTalent', query:{conditions: this.value}})
            },
            deleteClick() {
                this.searchArr = []
                localStorage.removeItem('searchArr')
            }
        }
    }
</script>

<style lang="less" scoped>
    .taskSearch{
        min-height: 100%;
        background: #F7F8F9;
        .flex{
            display: flex;
        }
        .delete-img{
            width: 0.2rem;
            height: 0.24rem;
        }
        .seach-history{
            padding: 0.34rem 0.3rem;
            .title{
                font-size: 0.3rem;
                color: #4A4A4A;
                justify-content: space-between;
                align-items: center;
            }
            .search-wrap{
                padding: 0.24rem 0;
                flex-wrap: wrap;
            }
            .search-item{
                font-size: 0.24rem;
                color: #4A4A4A;
                padding: 0.1rem 0.24rem;
                border-radius: 28px;
                border: 0.01rem solid #EBECED;
                margin-right: 0.2rem;
                margin-bottom: 0.24rem;
            }
        }
        .van-search__content{
            border-radius: 30px;
            border: 1px solid #3F7C53;
        }
        .van-search__action{
            color: #3F7C53;
        }
        .van-search .van-cell{
            padding: 0.05rem 0 0.05rem;
        }
        .sitezz-img{
            width: 1.36rem;
            height: 1.36rem;
            flex: 0 0 1.36rem;
            border-radius: 0.08rem;
        }
        .VDS-ShadowBg_home {
            border-radius: 4px;
            box-shadow: 0px 2.5px 5px 5px rgba(0, 0, 0, 0.04);
        }
        .toStation {
            width: 72px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            color: white;
            text-align: center;
            border-radius: 12px;
            background-color: #3f7c53;
            float: right;
        }
        .toLive {
            margin-right: 15px;
            width: 72px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            color: #3f7c53;
            text-align: center;
            border-radius: 12px;
            background-color: rgba(63, 124, 83, 0.1);
            float: right;
        }
        .site-item_list {
            padding-bottom: 55px;
            margin: 0 0.3rem;
            .site-item {
                margin-top: 12px;
                border-radius: 4px;
                padding: 0.3rem 0.3rem 0.4rem;
                .logo-img {
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 0.3rem;
                }
                .site-item_right {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: space-between;
                    .site-item_one {
                        height: 16px;
                        line-height: 16px;
                        justify-content: space-between;
                        align-items: flex-start;
                        .stationName {
                            margin-left: 10px;
                            font-size: 15px;
                            font-weight: bold;
                            color: #333333;
                        }
                    }
                    .site-item_tag {
                        margin-left: 8px;
                        color: #3f7c53;
                        border: solid 1px #D9E4DD;
                        height: 20px;
                        line-height: 20px;
                        border-radius: 4px;
                        font-size: 12px;
                        padding: 0 10px;
                    }
                    .site-item_performanceRange{
                        color: #3F7C53;
                        border-color: #D9E4DD;
                    }
                    .site-item_status {
                        font-size: 12px;
                        height: 20px;
                        line-height: 20px;
                        padding: 0 15px;
                        border-radius: 2px;
                    }
                    .site-item_failure{
                        color: #999;
                        border-color: rgba(0,0,0,0.1);
                    }
                    .site-item_status_one {
                        color: #56cb8f;
                        background-color: #e0f5eb;
                    }
                    .site-item_status_two {
                        color: #f4664a;
                        background-color: #ffeae6;
                    }
                    .site-item_status_three {
                        color: #3874db;
                        background-color: rgba(56, 116, 219, 0.1);
                    }
                    .site-item_status_four {
                        color: #999999;
                        background-color: rgba(153, 153, 153, 0.1);
                    }
                    .site-item_status_five{
                        color:#fff;
                        background: linear-gradient(155deg, #FCEAAE 0%, #E98D14 100%);
                    }
                    .site-item_stationStatus_one{
                        color: #F6AA2E;
                        border-color: #FFEFCB;
                    }
                    .site-item_stationStatus_two{
                        color: #F6AA2E;
                        border-color: #FFEFCB;
                    }
                    .site-item_stationStatus_three{
                        color: #56CB8F;
                        border-color: #E0F5EB;
                    }
                    .site-item_stationStatus_four{
                        color: #56CB8F;
                        border-color: #E0F5EB;
                    }
                    .site-item_stationStatus_five{
                        color: #F4664A;
                        border-color: #FFEAE6 ;
                    }
                    .stationLocate {
                        margin-left: 10px;
                        font-size: 13px;
                        color: #999999;
                        padding: 0.1rem 0 0.1rem;
                        word-wrap: break-word;
                        word-break: break-all;
                    }
                }
            }
        }
    }
</style>

<style lang="less">
    .taskSearch{
        .van-field__left-icon .van-icon, .van-field__right-icon .van-icon{
            font-size: 0.35rem;
        }
    }
</style>